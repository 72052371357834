import React from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import '../styles/pages/Homepage.css';
import VideoContainer from "../components/VideoContainer";
import { useNavigate } from 'react-router-dom';



const Homepage = () => {

    const navigate = useNavigate();

    const handlePurchaseClicked = () => {
        navigate('/purchase');
    };


    const handleTryDemoClicked = () => {
        navigate('/demo');
    };



    return (
        <div className="homepage">
            <header className="header">
                <Container>
                    <Row className="align-items-center">
                        <Col md={6}>
                            <h1 className="main-title">All the flexibility you need when calculating option prices</h1>
                            <p className="subtitle">
                                With our meticulously designed Price Option Excel plugin, you can focus less on the limitations of existing tools and fully leverage the ease and potential of our plugin in your projects.                            </p>
                            <Button className="cta-button" onClick={handleTryDemoClicked}>Try Demo</Button>
                        </Col>
                        <Col md={6}>
                            <VideoContainer videoSrc="/videos/2.mp4" />
                        </Col>
                    </Row>
                </Container>
            </header>
            <section className="features-section">
                <Container>
                    <Row>
                        <Col lg={4}>
                            <div className="feature-card">
                                <h3>Rapid Calculations</h3>
                                <p>Experience lightning-fast calculations with our Excel plugin. Designed for efficiency, it performs complex price option calculations in mere seconds. With a user-friendly interface and streamlined processing, you can quickly adjust variables and instantly see updated results, saving you valuable time and reducing manual entry errors.</p>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="feature-card">
                                <h3>Seamless Integration</h3>
                                <p>Our plugin integrates seamlessly with Excel, allowing you to harness the full power of Excel's data management and analysis capabilities. You can import data from various sources, utilize built-in Excel functions, and create custom reports, all while leveraging our plugin’s advanced calculation features to optimize pricing strategies.</p>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="feature-card">
                                <h3>Reliability and Accuracy</h3>
                                <p>Ensure precise and dependable results with our plugin’s robust algorithms. Designed to handle a wide range of pricing scenarios, it provides accurate calculations and detailed insights, minimizing the risk of errors. Regular updates and rigorous testing guarantee that the plugin remains reliable and up-to-date with industry standards.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="cta-section">
                <Container>
                    <Row>
                        <Col md={12} className="text-center">
                            <h2>Ready to calculate the right way?</h2>
                            <Button className="cta-button" onClick={handlePurchaseClicked}>Purchase Now</Button>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    );
};

export default Homepage;
