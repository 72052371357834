import React from 'react';
import Navigation from "../../components/Navigation";
import { Outlet } from "react-router-dom";
import Footer from "../../components/Footer";
import '../../styles/components/Footer.css'; // Ensure the CSS is imported

function MasterLayout() {
    return (
        <>
            <Navigation />
            <div className="content">
                <Outlet />
            </div>
            <Footer />
        </>
    );
}

export default MasterLayout;
