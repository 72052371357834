const jStat = require('jstat').jStat;

function calculate_d1(S, X, r, T, sigma) {
    return (Math.log(S / X) + (r + (Math.pow(sigma, 2) / 2)) * T) / (sigma * Math.sqrt(T));
}

function calculate_d2(d1, sigma, T) {
    return d1 - sigma * Math.sqrt(T);
}

function calculate_call_premium(S, X, r, T, sigma) {
    const d1 = calculate_d1(S, X, r, T, sigma);
    const d2 = calculate_d2(d1, sigma, T);
    return S * jStat.normal.cdf(d1, 0, 1) - X * Math.exp(-r * T) * jStat.normal.cdf(d2, 0, 1);
}

function calculate_put_premium(S, X, r, T, sigma) {
    const d1 = calculate_d1(S, X, r, T, sigma);
    const d2 = calculate_d2(d1, sigma, T);
    return X * Math.exp(-r * T) * jStat.normal.cdf(-d2, 0, 1) - S * jStat.normal.cdf(-d1, 0, 1);
}

module.exports = {
    calculate_call_premium,
    calculate_put_premium
};
