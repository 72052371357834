import React, { useState } from "react";
import '../../styles/pages/dashboard-inner/SettingsConfiguration.css';

function SettingsConfiguration() {
    const [settings, setSettings] = useState({
        // Data Sharing Settings
        shareUsageData: false,
        shareDataWithPartners: false,
        shareDataForResearch: false,
        shareAnonymizedData: true,
        shareDataWithThirdParties: true,

        // In-App Notifications
        notifyPaymentSuccess: true,
        notifyBillingIssues: true,
        notifyUpcomingPayments: true,
        notifySubscriptionRenewal: true,
        notifyLicenseExpiry: true,
        notifyBackendRequestFailure: true,

        // SMS Notifications
        receiveSMSBillingIssues: true,
        receiveSMSPaymentNotifications: false,
        receiveSMSPaymentReminders: true,
        receiveSMSPromotions: false,
        receiveSMSAccountSecurityAlerts: true,

        // Email Notifications
        receiveEmailBillingIssues: true,
        receiveEmailUpcomingPayment: true,
        receiveEmailPaymentSuccess: false,
        receiveEmailAccountActivity: true,
        receiveEmailPromotions: false,
    });

    const apiUrl = process.env.REACT_APP_DEV_OR_PROD === 'dev' ? process.env.REACT_APP_DEV_FRONT : process.env.REACT_APP_PROD_FRONT;

    const toggleSetting = (setting) => {
        setSettings((prevSettings) => ({
            ...prevSettings,
            [setting]: !prevSettings[setting],
        }));
    };

    return (
        <div className="expanded-container settings-config show">
            <h2>Account Settings</h2>
            <div className="settings-grid">
                <div className="settings-card">
                    <h3>Data Sharing</h3>
                    <div className="setting-item">
                        <span>Share usage data for improvements</span>
                        <label className="toggle-switch">
                            <input
                                type="checkbox"
                                checked={settings.shareUsageData}
                                onChange={() => toggleSetting('shareUsageData')}
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>
                    <div className="setting-item">
                        <span>Allow data sharing with partners</span>
                        <label className="toggle-switch">
                            <input
                                type="checkbox"
                                checked={settings.shareDataWithPartners}
                                onChange={() => toggleSetting('shareDataWithPartners')}
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>
                    <div className="setting-item">
                        <span>Share data for research purposes</span>
                        <label className="toggle-switch">
                            <input
                                type="checkbox"
                                checked={settings.shareDataForResearch}
                                onChange={() => toggleSetting('shareDataForResearch')}
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>
                    <div className="setting-item">
                        <span>Share anonymized data with third parties</span>
                        <label className="toggle-switch">
                            <input
                                type="checkbox"
                                checked={settings.shareAnonymizedData}
                                onChange={() => toggleSetting('shareAnonymizedData')}
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>
                    <div className="setting-item">
                        <span>Share data with other third parties</span>
                        <label className="toggle-switch">
                            <input
                                type="checkbox"
                                checked={settings.shareDataWithThirdParties}
                                onChange={() => toggleSetting('shareDataWithThirdParties')}
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>
                </div>

                <div className="settings-card">
                    <h3>In-App Notifications</h3>
                    <div className="setting-item">
                        <span>Notify on successful payments</span>
                        <label className="toggle-switch">
                            <input
                                type="checkbox"
                                checked={settings.notifyPaymentSuccess}
                                onChange={() => toggleSetting('notifyPaymentSuccess')}
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>
                    <div className="setting-item">
                        <span>Notify on billing issues</span>
                        <label className="toggle-switch">
                            <input
                                type="checkbox"
                                checked={settings.notifyBillingIssues}
                                onChange={() => toggleSetting('notifyPaymentFailure')}
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>
                    <div className="setting-item">
                        <span>Notify on upcoming payments</span>
                        <label className="toggle-switch">
                            <input
                                type="checkbox"
                                checked={settings.notifyUpcomingPayments}
                                onChange={() => toggleSetting('notifyUpcomingPayments')}
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>
                    <div className="setting-item">
                        <span>Notify on subscription renewal</span>
                        <label className="toggle-switch">
                            <input
                                type="checkbox"
                                checked={settings.notifySubscriptionRenewal}
                                onChange={() => toggleSetting('notifySubscriptionRenewal')}
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>
                    <div className="setting-item">
                        <span>Notify on license expiry</span>
                        <label className="toggle-switch">
                            <input
                                type="checkbox"
                                checked={settings.notifyLicenseExpiry}
                                onChange={() => toggleSetting('notifyLicenseExpiry')}
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>
                    <div className="setting-item">
                        <span>Notify on backend request failures</span>
                        <label className="toggle-switch">
                            <input
                                type="checkbox"
                                checked={settings.notifyBackendRequestFailure}
                                onChange={() => toggleSetting('notifyBackendRequestFailure')}
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>
                </div>

                <div className="settings-card">
                    <h3>SMS Notifications</h3>
                    <div className="setting-item">
                        <span>Receive SMS for billing issues</span>
                        <label className="toggle-switch">
                            <input
                                type="checkbox"
                                checked={settings.receiveSMSBillingIssues}
                                onChange={() => toggleSetting('receiveSMSBillingIssues')}
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>
                    <div className="setting-item">
                        <span>Receive SMS for payment notifications</span>
                        <label className="toggle-switch">
                            <input
                                type="checkbox"
                                checked={settings.receiveSMSPaymentNotifications}
                                onChange={() => toggleSetting('receiveSMSPaymentNotifications')}
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>
                    <div className="setting-item">
                        <span>Receive SMS payment reminders</span>
                        <label className="toggle-switch">
                            <input
                                type="checkbox"
                                checked={settings.receiveSMSPaymentReminders}
                                onChange={() => toggleSetting('receiveSMSPaymentReminders')}
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>
                    <div className="setting-item">
                        <span>Receive SMS promotions</span>
                        <label className="toggle-switch">
                            <input
                                type="checkbox"
                                checked={settings.receiveSMSPromotions}
                                onChange={() => toggleSetting('receiveSMSPromotions')}
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>
                    <div className="setting-item">
                        <span>Receive SMS account security alerts</span>
                        <label className="toggle-switch">
                            <input
                                type="checkbox"
                                checked={settings.receiveSMSAccountSecurityAlerts}
                                onChange={() => toggleSetting('receiveSMSAccountSecurityAlerts')}
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>
                </div>

                <div className="settings-card">
                    <h3>Email Notifications</h3>
                    <div className="setting-item">
                        <span>Receive emails for billing issues</span>
                        <label className="toggle-switch">
                            <input
                                type="checkbox"
                                checked={settings.receiveEmailBillingIssues}
                                onChange={() => toggleSetting('receiveEmailBillingIssues')}
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>
                    <div className="setting-item">
                        <span>Receive upcoming payment emails</span>
                        <label className="toggle-switch">
                            <input
                                type="checkbox"
                                checked={settings.receiveEmailUpcomingPayment}
                                onChange={() => toggleSetting('receiveEmailUpcomingPayment')}
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>
                    <div className="setting-item">
                        <span>Receive successful payment emails</span>
                        <label className="toggle-switch">
                            <input
                                type="checkbox"
                                checked={settings.receiveEmailPaymentSuccess}
                                onChange={() => toggleSetting('receiveEmailPaymentSuccess')}
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>
                    <div className="setting-item">
                        <span>Receive account activity emails</span>
                        <label className="toggle-switch">
                            <input
                                type="checkbox"
                                checked={settings.receiveEmailAccountActivity}
                                onChange={() => toggleSetting('receiveEmailAccountActivity')}
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>
                    <div className="setting-item">
                        <span>Receive promotional emails</span>
                        <label className="toggle-switch">
                            <input
                                type="checkbox"
                                checked={settings.receiveEmailPromotions}
                                onChange={() => toggleSetting('receiveEmailPromotions')}
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SettingsConfiguration;
