import React, {useEffect} from 'react';
import '../../styles/pages/dashboard-inner/DownloadSoftware.css';
import useAuth from "../../hooks/useAuth";
import useRefreshUser from "../../hooks/useRefreshUser";

function DownloadSoftware(props) {

    const licenseType = "No License Purchased";
    const licenseKey = "XXXX-XXXX-XXXX-XXXX";
    const softwareVersion = "Version 1.0.1";
    const userEmail = "user@example.com";

    const apiUrl = process.env.REACT_APP_DEV_OR_PROD === 'dev' ? process.env.REACT_APP_DEV_FRONT : process.env.REACT_APP_PROD_FRONT;

    const planType = () => {
        if (props.user.currentPlan === 0) {
            return "Not Subscribed"
        } else if (props.user.currentPlan === 1) {
            return "Basic | Monthly"
        } else if (props.user.currentPlan === 2) {
            return "Pro | Monthly"
        } else if (props.user.currentPlan === 3) {
            return "Enterprise | Monthly"
        } else if (props.user.currentPlan === 4) {
            return "Basic | Yearly"
        } else if (props.user.currentPlan === 5) {
            return "Pro | Yearly"
        } else if (props.user.currentPlan === 6) {
            return "Enterprise | Yearly"
        }
    }
    const licenseKeyShow = () => {
        if (!props.user.userLicenseId) {
            return "No User License Key"
        } else {
            return props.user.userLicenseId;
        }
    }

    const handleDownloadClick = () => {
        alert("Download started!");
        // Implement actual download logic here
    };

    return (
        <div className="expanded-container show">
            { props.backButton }
            <div className="expanded-content">
                <h2>Download Software</h2>
                <p><strong>License Type:</strong> {planType()}</p>
                <p><strong>License Key:</strong> {licenseKeyShow()}</p>
                <p><strong>Software Version:</strong> {softwareVersion}</p>
                <p><strong>Registered Email:</strong> {props.user.email}</p>
                <button className="download-button" onClick={handleDownloadClick}>
                    Download Software
                </button>
                {props.backButtonBlock}
                <p className="download-instructions">
                    Please make sure your license key is active before downloading the software.
                    If you encounter any issues, contact our support team.
                </p>
            </div>
        </div>
    );
}

export default DownloadSoftware;
