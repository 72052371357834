import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from 'react-icons/fa';
import '../styles/components/Footer.css';

function Footer() {
    return (
        <footer className="footer-container text-light py-4">
            <Container>
                <Row>
                    <Col md={4}>
                        <h5>About Us</h5>
                        <p>
                            We are a leading provider of stock derivative success software,
                            helping traders maximize their profits through innovative technology
                            and real-time market analysis.
                        </p>
                    </Col>
                    <Col md={4}>
                        <h5>Quick Links</h5>
                        <ul className="list-unstyled">
                            <li><a href="/home" className="text-light">Home</a></li>
                            <li><a href="/about" className="text-light">About</a></li>
                            <li><a href="/contact" className="text-light">Contact</a></li>
                            <li><a href="/purchase" className="text-light">Purchase</a></li>
                            <li><a href="/docs" className="text-light">Docs</a></li>
                            <li><a href="/demo" className="text-light">Demo</a></li>

                        </ul>
                    </Col>
                    <Col md={4}>
                        <h5>Follow Us</h5>
                        <div className="d-flex">
                            <a href="https://facebook.com" className="text-light me-3"><FaFacebook size={30} /></a>
                            <a href="https://twitter.com" className="text-light me-3"><FaTwitter size={30} /></a>
                            <a href="https://linkedin.com" className="text-light me-3"><FaLinkedin size={30} /></a>
                            <a href="https://instagram.com" className="text-light"><FaInstagram size={30} /></a>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col className="text-center">
                        <p>&copy; {new Date().getFullYear()} Modo. All rights reserved.</p>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}

export default Footer;
