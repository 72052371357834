import React, { useState } from 'react';
import '../../styles/pages/dashboard-inner/PaymentDetails.css';
import useAuth from "../../hooks/useAuth";
import axios from 'axios';

function PaymentDetails() {
    const { user, csrfToken } = useAuth();
    const [loading, setLoading] = useState(false);

    const apiUrl = process.env.REACT_APP_DEV_OR_PROD === 'dev' ? process.env.REACT_APP_DEV_FRONT : process.env.REACT_APP_PROD_FRONT;

    const handleManagePaymentDetails = async () => {
        setLoading(true);

        try {
            const response = await axios.post(
                `${apiUrl}/server/manage-payment-details`,
                {
                    customerId: user.stripeCustomerId,
                },
                {
                    headers: {
                        'CSRF-Token': csrfToken
                    },
                    withCredentials: true // Ensures cookies are sent with the request
                }
            );

            if (response.status === 200 && response.data.url) {
                window.location.href = response.data.url; // Redirects to Stripe's portal
            } else {
                alert("Failed to create session. Please try again.");
            }
        } catch (error) {
            console.error("Error managing payment details:", error);
            alert("An error occurred while managing your payment details. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="expanded-container payment-details show">
            <div className="payment-details-container">
                <h2>Payment Details</h2>
                <p>All your payment details can be viewed and modified on Stripe by clicking the button below.</p>
                <button
                    className="manage-payment-button"
                    onClick={handleManagePaymentDetails}
                    disabled={loading}
                >
                    {loading ? 'Processing...' : 'Manage Payment Details'}
                </button>
            </div>
        </div>
    );
}

export default PaymentDetails;
