import React, { useState } from 'react';
import useAuth from '../../hooks/useAuth';
import '../../styles/pages/dashboard-inner/PlanDetails.css';
import axios from "axios";

function PlanDetails() {
    const { user, csrfToken } = useAuth();
    const [loading, setLoading] = useState(false);

    const apiUrl = process.env.REACT_APP_DEV_OR_PROD === 'dev' ? process.env.REACT_APP_DEV_FRONT : process.env.REACT_APP_PROD_FRONT;

    const planDetails = {
        1: {
            name: 'Basic Plan',
            features: [
                'Basic price calculation tools',
                '1 License Included',
                'Email support',
            ]
        },
        2: {
            name: 'Pro Plan',
            features: [
                'Basic price calculation tools',
                'Live Exchange Feed',
                '1 License Included',
                'Priority email support',
                'Customizable templates',
            ]
        },
        3: {
            name: 'Enterprise Plan',
            features: [
                'All Pro features',
                'License for multiple users',
                'Dedicated account manager',
                '24/7 phone support',
                'Custom integrations',
            ]
        },
        4: {
            name: 'Basic Plan',
            features: [
                'Basic price calculation tools',
                '1 License Included',
                'Email support',
                'Yearly discount included',
            ]
        },
        5: {
            name: 'Pro Plan',
            features: [
                'Basic price calculation tools',
                'Live Exchange Feed',
                '1 License Included',
                'Priority email support',
                'Customizable templates',
                'Yearly discount included',
            ]
        },
        6: {
            name: 'Enterprise Plan',
            features: [
                'All Pro features',
                'License for multiple users',
                'Dedicated account manager',
                '24/7 phone support',
                'Custom integrations',
                'Yearly discount included',
            ]
        }
    };

    const currentPlan = planDetails[user.currentPlan];
    const billingCycle = user.currentPlan <= 3 ? 'Monthly' : 'Yearly';
    const nextBillingDate = user.nextBillingDate
        ? new Date(user.nextBillingDate).toLocaleDateString()
        : 'N/A';

    const handleManagePlan = async () => {
        setLoading(true);

        try {
            const response = await axios.post(
                `${apiUrl}/server/manage-payment-details`,
                { customerId: user.stripeCustomerId },
                {
                    headers: { 'CSRF-Token': csrfToken },
                    withCredentials: true,
                }
            );

            if (response.status === 200 && response.data.url) {
                window.location.href = response.data.url; // Redirects to Stripe's portal
            } else {
                alert('Failed to create session. Please try again.');
            }
        } catch (error) {
            console.error('Error managing plan:', error);
            alert('An error occurred while managing your plan. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="expanded-container plan-details show">
            <div className="plan-details-grid">
                <div className="plan-info-card">
                    <div className="plan-card">
                        <h2>{currentPlan.name}</h2>
                        <p><strong>Billing Cycle:</strong> {billingCycle}</p>
                        <p><strong>Next Billing Date:</strong> {nextBillingDate}</p>
                    </div>
                    <div className="features-card">
                        <h3>Plan Features</h3>
                        <ul>
                            {currentPlan.features.map((feature, index) => (
                                <li key={index}>{feature}</li>
                            ))}
                        </ul>
                        {user.currentPlan === 0 && (
                            <p>You are not currently subscribed to any plan. Please consider subscribing to a plan to access premium features.</p>
                        )}
                    </div>
                </div>
                <div className="manage-plan-card">
                    <button
                        className="manage-plan-button"
                        onClick={handleManagePlan}
                        disabled={loading}
                    >
                        {loading ? 'Processing...' : 'Upgrade/Downgrade Plan'}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default PlanDetails;
