import React, { useState, useEffect } from 'react';
import '../../styles/pages/dashboard-inner/PersonalDetails.css';
import useAuth from "../../hooks/useAuth";
import axios from 'axios';

function PersonalDetails() {
    const { user, refreshUserDetailsByModoId, csrfToken } = useAuth();
    const [personalInfo, setPersonalInfo] = useState({
        firstName: '',
        lastName: '',
        email: '',
    });

    const apiUrl = process.env.REACT_APP_DEV_OR_PROD === 'dev' ? process.env.REACT_APP_DEV_FRONT : process.env.REACT_APP_PROD_FRONT;

    useEffect(() => {
        if (user) {
            setPersonalInfo({
                firstName: user.firstName || '',
                lastName: user.lastName || '',
                email: user.email || '',
            });
        }
    }, [user]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPersonalInfo((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleBlur = (e) => {
        const { name, value } = e.target;

        if (!value.trim()) {
            setPersonalInfo((prevState) => ({
                ...prevState,
                [name]: user[name] || '',
            }));
        }
    };

    const handleSave = async () => {
        // Reset fields to original if empty
        if (!personalInfo.firstName.trim()) {
            setPersonalInfo((prevState) => ({
                ...prevState,
                firstName: user.firstName || '',
            }));
        }
        if (!personalInfo.lastName.trim()) {
            setPersonalInfo((prevState) => ({
                ...prevState,
                lastName: user.lastName || '',
            }));
        }

        try {
            const response = await axios.post(`${apiUrl}/server/update-flname`, {
                modoId: user.id, // Make sure to pass modoId if required by the backend
                firstName: personalInfo.firstName,
                lastName: personalInfo.lastName,
            }, {
                headers: {
                    'CSRF-Token': csrfToken
                },
                withCredentials: true // Ensures cookies are sent with the request
            });

            if (response.status === 200) {
                alert("Personal details updated successfully!");
                // Optionally, refresh user details from the backend to ensure the UI reflects the latest data
                await refreshUserDetailsByModoId(user.id);
            } else {
                alert("Failed to update personal details.");
            }
        } catch (error) {
            console.error("Error updating personal details:", error);
            alert("An error occurred while updating your details. Please try again.");
        }
    };

    return (
        <div className="expanded-container personal-details show">
            <div className="personal-details-container">
                <h2>Personal Details</h2>
                <div className="form-group">
                    <label htmlFor="firstName">First Name</label>
                    <input
                        type="text"
                        id="firstName"
                        name="firstName"
                        value={personalInfo.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="lastName">Last Name</label>
                    <input
                        type="text"
                        id="lastName"
                        name="lastName"
                        value={personalInfo.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={personalInfo.email}
                        onChange={handleChange}
                        disabled // Assume email is not editable in this example
                    />
                </div>
                <button className="save-button" onClick={handleSave}>
                    Save Changes
                </button>
            </div>
        </div>
    );
}

export default PersonalDetails;
