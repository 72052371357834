import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import '../styles/pages/Register.css';

const Register = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [messages, setMessages] = useState([]);
    const [csrfToken, setCsrfToken] = useState('');
    const navigate = useNavigate();

    const apiUrl = process.env.REACT_APP_DEV_OR_PROD === 'dev' ? process.env.REACT_APP_DEV_FRONT : process.env.REACT_APP_PROD_FRONT;

    useEffect(() => {
        const fetchCsrfToken = async () => {
            try {
                const response = await axios.get(`${apiUrl}/auth/csrf-token`);
                setCsrfToken(response.data.csrfToken);
            } catch (error) {
                console.error('Failed to fetch CSRF token:', error);
            }
        };

        fetchCsrfToken();
    }, []);

    const handleRegister = async (e) => {
        e.preventDefault();
        if (password !== password2) {
            setMessages(['Passwords do not match']);
            return;
        }

        try {
            const response = await axios.post(`${apiUrl}/auth/register`, { username, password, email, firstName, lastName }, {
                headers: {
                    'CSRF-Token': csrfToken
                }
            });
            alert('Registration successful!');
            navigate('/login'); // Redirect to login page on successful registration
        } catch (error) {
            console.error('Registration failed:', error);
            setMessages(['Registration failed, please try again.']);
        }
    };

    return (
        <div className="full-screen-container">
            <Container className="form-container">
                <Row className="justify-content-md-center">
                    <Col>
                        <h2 className="text-center">Register</h2>
                        <Form onSubmit={handleRegister}>
                            <div className="form-row">
                                <Form.Group className="mb-3 form-modifications" controlId="formFirstName">
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter first name"
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        required
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3 form-modifications" controlId="formLastName">
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter last name"
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                        required
                                    />
                                </Form.Group>
                            </div>

                            <Form.Group className="mb-3 form-modifications" controlId="formUsername">
                                <Form.Label>Username</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter username"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    required
                                />
                            </Form.Group>

                            <Form.Group className="mb-3 form-modifications" controlId="formEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="Enter email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </Form.Group>

                            <div className="form-row form-modifications">
                                <Form.Group className="mb-3 form-modifications" controlId="formPassword">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="Enter password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3 form-modifications" controlId="formPassword2">
                                    <Form.Label>Confirm Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="Confirm password"
                                        value={password2}
                                        onChange={(e) => setPassword2(e.target.value)}
                                        required
                                    />
                                </Form.Group>
                            </div>

                            {messages.length > 0 && (
                                <div className="alert alert-danger">
                                    {messages.map((message, index) => (
                                        <p key={index}>{message}</p>
                                    ))}
                                </div>
                            )}

                            <Button variant="primary" type="submit" className="w-100 mb-3 mt-4 p-2">
                                Register
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Register;
