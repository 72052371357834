import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthProvider';

const Success = () => {
    const navigate = useNavigate();
    const { setUser } = useContext(AuthContext);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                console.log('Fetching user data from /auth/success...');
                const response = await fetch('http://localhost:5000/auth/success', {
                    method: 'GET',
                    credentials: 'include'
                });
                const data = await response.json();
                console.log('Response data:', data);
                if (data.user) {
                    console.log('User data found:', data.user);
                    setUser(data.user);
                    navigate('/dashboard');
                } else {
                    console.log('No user data found, redirecting to /auth/fail');
                    navigate('/auth/fail');
                }
            } catch (error) {
                console.error('Failed to fetch user:', error);
                navigate('/auth/fail');
            }
        };

        fetchUser();
    }, [navigate, setUser]);

    return (
        <div>
            <h2>Logging you in...</h2>
        </div>
    );
};

export default Success;
