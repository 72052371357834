import React, { useEffect, useState } from 'react';
import {Row, Col, Card, Button, Spinner} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faArrowUp,
    faCreditCard,
    faClipboardList,
    faComments,
    faChartPie, faClock, faUserCheck, faDollarSign
} from '@fortawesome/free-solid-svg-icons';
import useAuth from "../hooks/useAuth";
import '../styles/pages/Dashboard.css';
import PaymentDetails from "../components/dashboard/PaymentDetails";
import PropTypes from "prop-types"; // Ensure correct path

function Overview() {
    return (
        <Row>
            <p></p>
            <Col lg={9}>
                <Card className="mb-4">
                    <Card.Body>
                        <Card.Title>Business Plan</Card.Title>
                        <p></p>
                        <p>12 of 20 users</p>
                        <Button variant="primary">
                            <FontAwesomeIcon icon={faArrowUp} /> Upgrade Plan
                        </Button>
                    </Card.Body>
                </Card>
                <Card className="mb-4">
                    <Card.Body>
                        <Card.Title>Next Payment</Card.Title>
                        <p>on November 30, 2020</p>
                        <Button variant="secondary">
                            <FontAwesomeIcon icon={faCreditCard} /> Manage Payments
                        </Button>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        <Card.Title>Invoices</Card.Title>
                        <p></p>
                        <ul className="invoice-list">
                            <li className="invoice-item">
                                <span>Invoice_2020/10.pdf</span>
                                <span>Nov 02, 2020</span>
                            </li>
                            <li className="invoice-item">
                                <span>Invoice_2020/09.pdf</span>
                                <span>Oct 02, 2020</span>
                            </li>
                            <li className="invoice-item">
                                <span>Invoice_2020/08.pdf</span>
                                <span>Sep 02, 2020</span>
                            </li>
                        </ul>
                    </Card.Body>
                </Card>
            </Col>
            <Col lg={3}>
                <Card className="sidebar">
                    <Card.Body>
                        <p><FontAwesomeIcon icon={faChartPie} /> Analytics</p>
                        <p><FontAwesomeIcon icon={faClipboardList} /> Company Benefits</p>
                        <p><FontAwesomeIcon icon={faComments} /> Surveys</p>
                        <p><FontAwesomeIcon icon={faClock} /> Time Tracking</p>
                        <p><FontAwesomeIcon icon={faUserCheck} /> Onboarding</p>
                        <p><FontAwesomeIcon icon={faDollarSign} /> Subscription</p>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
}

function Plans() {
    return (
        <Row>
            <p></p>
            <Col lg={4}>
                <Card className="mb-4">
                    <Card.Body>
                        <Card.Title>Basic Plan</Card.Title>
                        <p>Price: $10/month</p>
                        <ul>
                            <li>5 Users</li>
                            <li>10GB Storage</li>
                            <li>Email Support</li>
                        </ul>
                        <Button variant="primary">Upgrade to Basic</Button>
                    </Card.Body>
                </Card>
            </Col>
            <Col lg={4}>
                <Card className="mb-4">
                    <Card.Body>
                        <Card.Title>Pro Plan</Card.Title>
                        <p>Price: $20/month</p>
                        <ul>
                            <li>10 Users</li>
                            <li>50GB Storage</li>
                            <li>Priority Email Support</li>
                        </ul>
                        <Button variant="primary">Upgrade to Pro</Button>
                    </Card.Body>
                </Card>
            </Col>
            <Col lg={4}>
                <Card className="mb-4">
                    <Card.Body>
                        <Card.Title>Premium Plan</Card.Title>
                        <p>Price: $30/month</p>
                        <ul>
                            <li>Unlimited Users</li>
                            <li>100GB Storage</li>
                            <li>24/7 Phone Support</li>
                        </ul>
                        <Button variant="primary">Upgrade to Premium</Button>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
}

function UserDetails({ userContext }) {
    if (!userContext) {
        return (
            <Card className="mb-4">
                <Card.Body className="text-center">
                    <Spinner animation="border" variant="primary" />
                </Card.Body>
            </Card>
        );
    }

    const { firstName, lastName, email, userLicenseId } = userContext;

    return (
        <Card className="mb-4">
            <Card.Body>
                <Card.Title>User Details</Card.Title>
                <Row>
                    <Col md={6}>
                        <Card className="mb-4">
                            <Card.Body>
                                <Card.Title>Profile</Card.Title>
                                <p>Name: {firstName} {lastName}</p>
                                <p>Email: {email}</p>
                                <p>Licence Key: {userLicenseId}</p>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6}>
                        <Card className="mb-4">
                            <Card.Body>
                                <Card.Title>Settings</Card.Title>
                                <Button variant="primary" className="mr-2 mb-2">Edit Profile</Button>
                                <Button variant="secondary" className="mb-2">Change Password</Button>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
}

UserDetails.propTypes = {
    userContext: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        userLicenseId: PropTypes.string.isRequired,
    })
};


function Invoices() {
    return (
        <Card className="mb-4">
            <Card.Body>
                <Card.Title>Invoices</Card.Title>
                <p></p>
                <ul className="invoice-list">
                    <li className="invoice-item">
                        <span>Invoice_2020/10.pdf</span>
                        <span>Nov 02, 2020</span>
                    </li>
                    <li className="invoice-item">
                        <span>Invoice_2020/09.pdf</span>
                        <span>Oct 02, 2020</span>
                    </li>
                    <li className="invoice-item">
                        <span>Invoice_2020/08.pdf</span>
                        <span>Sep 02, 2020</span>
                    </li>
                </ul>
            </Card.Body>
        </Card>
    );
}

function Dashboard() {
    const [headerMargin, setHeaderMargin] = useState("0");
    const [activeSection, setActiveSection] = useState("overview");
    const { user } = useAuth();

    useEffect(() => {
        setHeaderMargin("20px");
    }, []);

    const handleNavClick = (section, event) => {
        event.preventDefault();
        console.log(`Changing active section from ${activeSection} to ${section}`);
        setActiveSection(section);
    };

    const getNavClassName = (section) => {
        const className = activeSection === section ? "nav-link active" : "nav-link";
        console.log(`Class for ${section}: ${className}`);
        return className;
    };

    return (
        <div className="dashboard-container">
            <div className="main-content">
                <div className="subscription-header" style={{ marginTop: headerMargin }}>
                    <h1>Subscription</h1>
                    <nav className="subscription-nav">
                        <a
                            href="#"
                            onClick={(e) => handleNavClick("overview", e)}
                            className={getNavClassName("overview")}
                        >
                            Overview
                        </a>
                        <a
                            href="#"
                            onClick={(e) => handleNavClick("plans", e)}
                            className={getNavClassName("plans")}
                        >
                            Plans
                        </a>
                        <a
                            href="#"
                            onClick={(e) => handleNavClick("UserDetails", e)}
                            className={getNavClassName("UserDetails")}
                        >
                            User Details
                        </a>
                        <a
                            href="#"
                            onClick={(e) => handleNavClick("invoices", e)}
                            className={getNavClassName("invoices")}
                        >
                            Invoices
                        </a>
                        <a
                            href="#"
                            onClick={(e) => handleNavClick("paymentDetails", e)}
                            className={getNavClassName("paymentDetails")}
                        >
                            Payment Details
                        </a>
                    </nav>
                </div>
                {activeSection === "overview" && <Overview />}
                {activeSection === "plans" && <Plans />}
                {activeSection === "UserDetails" && <UserDetails userContext={user} />}
                {activeSection === "invoices" && <Invoices />}
                {activeSection === "paymentDetails" && <PaymentDetails />}
            </div>
        </div>
    );
}

export default Dashboard;
