import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import '../styles/pages/Login.css';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const { login } = useAuth();
    const navigate = useNavigate();

    const apiUrl = process.env.REACT_APP_DEV_OR_PROD === 'dev' ? process.env.REACT_APP_DEV_FRONT : process.env.REACT_APP_PROD_FRONT;

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            console.log('Attempting login with username:', username);
            await login(username, password);
            console.log('Login successful, navigating to dashboard');
            navigate('/dashboard');
        } catch (error) {
            console.error('Login failed:', error);
            alert('Login failed, please try again.');
        }
    };

    const handleGoogleLogin = () => {
        window.location.href = `${apiUrl}/auth/google`;
    };

    return (
        <div className="full-screen-container">
            <Container className="form-container">
                <Row className="justify-content-md-center">
                    <Col>
                        <h2>Login</h2>
                        <Form onSubmit={handleLogin}>
                            <Form.Group className="mb-3 form-modifications" controlId="formUsername">
                                <Form.Label>Username</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter username"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    required
                                />
                            </Form.Group>

                            <Form.Group className="mb-3 form-modifications" controlId="formPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="Enter password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                            </Form.Group>

                            <Button variant="primary" type="submit" className="w-100 mb-3 mt-4 p-2">
                                Login
                            </Button>

                            <Button
                                variant="danger"
                                onClick={handleGoogleLogin}
                                className="w-100 p-2"
                            >
                                <FontAwesomeIcon icon={faGoogle} style={{ marginRight: '8px' }} />
                                Login with Google
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Login;
