import './styles/App.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import MasterLayout from './pages/master/MasterLayout';
import Homepage from './pages/Homepage.js';
import Purchase from './pages/Purchase.js';
import Contact from './pages/Contact.js';
import About from './pages/About.js';
import Docs from "./pages/Docs";
import Login from "./pages/Login";
import { AuthProvider, AuthContext } from "./context/AuthProvider";
import Register from "./pages/Register";
import ProtectedRoute from "./utils/ProtectedRoute";
import Dashboard from "./pages/Dashboard";
import Success from "./pages/redirectable/Success";
import Fail from "./pages/redirectable/Fail";
import { useEffect } from 'react';
import useAuth from './hooks/useAuth';
import Demo from "./pages/Demo";
import DashboardReplacement from "./pages/DashboardReplacement";
import ErrorPage404 from "./pages/ErrorPage404";
import Cancel from "./pages/Cancel";

function App() {
    const { refreshAccessToken } = useAuth();
    useEffect(() => {
        // Refresh token periodically
        const interval = setInterval(() => {
            refreshAccessToken();
        }, 15 * 60 * 1000); // Refresh every 15 minutes

        return () => clearInterval(interval);
    }, [refreshAccessToken]);

    return (
        <AuthProvider>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<MasterLayout />}>
                        <Route path="home" element={<Homepage />} />
                        <Route path="about" element={<About />} />
                        <Route path="contact" element={<Contact />} />
                        <Route
                            path="purchase"
                            element={
                                <AuthContext.Consumer>
                                    {({ csrfToken, user, loading }) => (
                                        <Purchase csrfToken={csrfToken} user={user} loading={loading} />
                                    )}
                                </AuthContext.Consumer>
                            }
                        />
                        <Route path="docs" element={<Docs />} />
                        <Route path="demo" element={<Demo />} />
                        <Route path="login" element={<Login />} />
                        <Route path="register" element={<Register />} />
                        <Route path="auth/success" element={<Success />} />
                        <Route path="auth/fail" element={<Fail />} />
                        <Route path="cancel" element={<Cancel />} />
                        <Route path="success" element={<Success />} />
                        <Route element={<ProtectedRoute />}>
                            <Route path="dashboard" element={<DashboardReplacement />} />
                        </Route>
                        <Route index element={<Navigate replace to="/home" />} />
                        {/* Error page route */}
                        <Route path="error" element={<ErrorPage404 />} />
                        {/* Redirect to /error for unknown paths */}
                        <Route path="*" element={<Navigate to="/error" replace />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </AuthProvider>
    );
}

export default App;
