import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

const ProtectedRoute = () => {
    const { user, loading } = useAuth();

    console.log('ProtectedRoute - user:', user, 'loading:', loading);

    if (loading) {
        return <div>Loading...</div>; // Show loading state until user context is initialized
    }

    return user ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoute;
