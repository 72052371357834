import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
import '../styles/pages/Demo.css';
import { calculate_call_premium, calculate_put_premium } from '../special/demo';

// Register the necessary chart components
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

function Demo() {
    const navigate = useNavigate();

    const [currentStockPrice, setCurrentStockPrice] = useState(100);
    const [riskFreeRate, setRiskFreeRate] = useState(2);
    const [optionStyle, setOptionStyle] = useState('European');
    const [positions, setPositions] = useState([
        {
            id: 1,
            buySell: 'Buy',
            quantity: 1,
            optionType: 'Call',
            strike: 100,
            daysToExpiry: 365,
            volatility: 30,
            premium: 0,
            debitCredit: -12.846 // Example value
        }
    ]);

    const [daysFromToday, setDaysFromToday] = useState(0);
    const [chartVolatility, setChartVolatility] = useState(30);
    const [chartRiskFreeRate, setChartRiskFreeRate] = useState(2);
    const [minPrice, setMinPrice] = useState('Auto');
    const [maxPrice, setMaxPrice] = useState('Auto');

    useEffect(() => {
        const newPositions = positions.map(position => {
            const updatedPosition = { ...position };
            calculatePremium(updatedPosition);
            return updatedPosition;
        });
        setPositions(newPositions);
    }, [currentStockPrice, riskFreeRate, optionStyle]);

    const handlePositionChange = (id, key, value) => {
        const updatedPositions = positions.map(position => {
            if (position.id === id) {
                const updatedPosition = { ...position, [key]: value };
                calculatePremium(updatedPosition);
                return updatedPosition;
            }
            return position;
        });
        setPositions(updatedPositions);
    };

    const calculatePremium = (position) => {
        const { optionType, strike, daysToExpiry, volatility } = position;

        const S = parseFloat(currentStockPrice);
        const X = parseFloat(strike);
        const r = parseFloat(riskFreeRate) / 100;
        const T = parseFloat(daysToExpiry) / 365;
        const sigma = parseFloat(volatility) / 100;

        if (S <= 0 || X <= 0 || T <= 0 || sigma <= 0) {
            position.premium = 'Invalid Input';
            position.debitCredit = 'Invalid Input';
            return;
        }

        const callPremium = calculate_call_premium(S, X, r, T, sigma);
        const putPremium = calculate_put_premium(S, X, r, T, sigma);

        const calculatedPremium = optionType === 'Call' ? callPremium : putPremium;
        const calculatedDebitCredit = position.buySell === 'Buy' ? -calculatedPremium * position.quantity : calculatedPremium * position.quantity;

        position.premium = calculatedPremium.toFixed(4);
        position.debitCredit = calculatedDebitCredit.toFixed(4);
    };

    const addPosition = () => {
        if (positions.length < 5) {
            const newPosition = {
                id: positions.length + 1,
                buySell: 'Buy',
                quantity: 1,
                optionType: 'Call',
                strike: 100,
                daysToExpiry: 365,
                volatility: 30,
                premium: 0,
                debitCredit: 0
            };
            calculatePremium(newPosition);
            setPositions([...positions, newPosition]);
        }
    };

    const removePosition = (id) => {
        setPositions(positions.filter(position => position.id !== id));
    };

    const calculatePayoffAtExpiry = (S, position) => {
        const { optionType, strike, buySell } = position;
        const X = parseFloat(strike);

        let payoff;
        if (optionType === 'Call') {
            payoff = Math.max(0, S - X); // Intrinsic value at expiry for call
        } else if (optionType === 'Put') {
            payoff = Math.max(0, X - S); // Intrinsic value at expiry for put
        }

        const initialDebitCredit = parseFloat(position.debitCredit);

        // Adjust the payoff to include the initial debit/credit
        return buySell === 'Buy'
            ? payoff + initialDebitCredit
            : initialDebitCredit - payoff;
    };

    const calculateProfitLoss = (S, position, daysToExpiry) => {
        const { optionType, strike, volatility, buySell } = position;
        const X = parseFloat(strike);
        const r = Number(riskFreeRate) / 100; // Ensure riskFreeRate is treated as a number
        const T = daysToExpiry / 365;
        const sigma = parseFloat(volatility) / 100;

        let premium;
        if (T > 0) {
            // Calculate the time value component of the option
            premium = optionType === 'Call'
                ? calculate_call_premium(S, X, r, T, sigma)
                : calculate_put_premium(S, X, r, T, sigma);
        } else {
            // At expiry, the value is purely intrinsic
            return calculatePayoffAtExpiry(S, position);
        }

        const initialDebitCredit = parseFloat(position.debitCredit);

        // Calculate the current value of the option "In X Days"
        const optionValue = buySell === 'Buy'
            ? premium
            : -premium;

        // Adjust the profit/loss calculation
        return optionValue + initialDebitCredit;
    };



    const generateChartData = () => {
        const priceRange = [];
        const Smin = minPrice === 'Auto' ? Math.max(currentStockPrice * 0.8, 0) : parseFloat(minPrice);
        const Smax = maxPrice === 'Auto' ? currentStockPrice * 1.2 : parseFloat(maxPrice);

        for (let S = Smin; S <= Smax; S += (Smax - Smin) / 100) {
            priceRange.push(S);
        }

        const todayData = priceRange.map(S => positions.reduce((acc, position) => acc + calculateProfitLoss(S, position, position.daysToExpiry - daysFromToday), 0));
        const expiryData = priceRange.map(S => positions.reduce((acc, position) => acc + calculatePayoffAtExpiry(S, position), 0));

        const allData = todayData.concat(expiryData);
        const minY = Math.min(...allData)-2.5;
        const maxY = Math.max(...allData)+2.5;

        return {
            labels: priceRange,
            datasets: [
                {
                    label: `In ${daysFromToday} Days`,
                    data: todayData,
                    fill: false,
                    borderColor: 'rgb(80,238,255)',
                    borderWidth: 2
                },
                {
                    label: 'At Expiry',
                    data: expiryData,
                    fill: false,
                    borderColor: 'white',
                    borderWidth: 2
                }
            ],
            minY,
            maxY
        };
    };

    const chartData = generateChartData();

    const chartOptions = {
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Price',
                    color: 'white',
                },
                ticks: {
                    color: 'white',
                },
                grid: {
                    color: 'rgba(255, 255, 255, 0.1)',
                },
            },
            y: {
                title: {
                    display: true,
                    text: 'PROFIT / LOSS',
                    color: 'white',
                },
                ticks: {
                    color: 'white',
                },
                grid: {
                    color: 'rgba(255, 255, 255, 0.1)',
                },
                beginAtZero: false,
                min: chartData.minY,
                max: chartData.maxY,
            },
        },
        plugins: {
            legend: {
                labels: {
                    color: 'white',
                },
            },
            tooltip: {
                callbacks: {
                    label: function(context) {
                        return `(${context.label}, ${context.raw.toFixed(2)})`;
                    }
                }
            }
        },
    };

    const totalDebitCredit = positions.reduce((acc, position) => acc + parseFloat(position.debitCredit), 0).toFixed(4);

    return (
        <div className="demo-page">
            <h1>Options Strategy Builder & Analyzer Online</h1>
            {/* Input Section */}
            <div className="input-section">
                <div className="input-group">
                    <label>Current Stock Price</label>
                    <input
                        type="number"
                        value={currentStockPrice}
                        onChange={(e) => setCurrentStockPrice(parseFloat(e.target.value) || 0)}
                    />
                </div>
                <div className="input-group">
                    <label>Risk-free Rate (%)</label>
                    <input
                        type="number"
                        value={riskFreeRate}
                        onChange={(e) => setRiskFreeRate(parseFloat(e.target.value) || 0)}
                    />
                </div>
                <div className="input-group">
                    <label>Option Style</label>
                    <select
                        value={optionStyle}
                        onChange={(e) => setOptionStyle(e.target.value)}
                    >
                        <option value="European">European</option>
                        <option value="American">American</option>
                    </select>
                </div>
            </div>

            {/* Positions Table */}
            <table className="positions-table">
                <thead>
                <tr>
                    <th>Buy / Sell</th>
                    <th>Quantity</th>
                    <th>Call / Put / Stock</th>
                    <th>Strike</th>
                    <th>Days to Expiry</th>
                    <th>Volatility, %</th>
                    <th>Premium</th>
                    <th>Debit / Credit</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {positions.map((position) => (
                    <tr key={position.id}>
                        <td>
                            <select
                                value={position.buySell}
                                onChange={(e) => handlePositionChange(position.id, 'buySell', e.target.value)}
                            >
                                <option value="Buy">Buy</option>
                                <option value="Sell">Sell</option>
                            </select>
                        </td>
                        <td>
                            <input
                                type="number"
                                value={position.quantity}
                                onChange={(e) => handlePositionChange(position.id, 'quantity', parseInt(e.target.value) || 0)}
                            />
                        </td>
                        <td>
                            <select
                                value={position.optionType}
                                onChange={(e) => handlePositionChange(position.id, 'optionType', e.target.value)}
                            >
                                <option value="Call">Call</option>
                                <option value="Put">Put</option>
                            </select>
                        </td>
                        <td>
                            <input
                                type="number"
                                value={position.strike}
                                onChange={(e) => handlePositionChange(position.id, 'strike', parseFloat(e.target.value) || 0)}
                            />
                        </td>
                        <td>
                            <input
                                type="number"
                                value={position.daysToExpiry}
                                onChange={(e) => handlePositionChange(position.id, 'daysToExpiry', parseInt(e.target.value) || 0)}
                            />
                        </td>
                        <td>
                            <input
                                type="number"
                                value={position.volatility}
                                onChange={(e) => handlePositionChange(position.id, 'volatility', parseFloat(e.target.value) || 0)}
                            />
                        </td>
                        <td>
                            <input
                                type="text"
                                value={position.premium}
                                readOnly
                            />
                        </td>
                        <td>
                            <input
                                type="text"
                                value={position.debitCredit}
                                readOnly
                            />
                        </td>
                        <td>
                            <button className="remove-button" onClick={() => removePosition(position.id)}>Remove</button>
                        </td>
                    </tr>
                ))}
                <tr>
                    <td colSpan="7">Total</td>
                    <td>{totalDebitCredit}</td>
                    <td></td>
                </tr>
                </tbody>
            </table>

            {/* Add Position Button */}
            <button className="add-button" onClick={addPosition} disabled={positions.length >= 5}>Add Position</button>

            {/* Chart Section */}
            <div className="chart-section">
                <h2>Options Strategy P/L Chart</h2>
                <div className="chart-controls">
                    <div className="input-group">
                        <label>Days from Today</label>
                        <div className="days-control">
                            <button onClick={() => setDaysFromToday(daysFromToday - 1)}>-</button>
                            <input type="number" value={daysFromToday} readOnly />
                            <button onClick={() => setDaysFromToday(daysFromToday + 1)}>+</button>
                        </div>
                    </div>
                    <div className="input-group">
                        <label>Volatility</label>
                        <input
                            type="number"
                            value={chartVolatility}
                            onChange={(e) => setChartVolatility(parseFloat(e.target.value) || 0)}
                        />
                        <span>%</span>
                    </div>
                    <div className="input-group">
                        <label>Risk-free Rate</label>
                        <input
                            type="number"
                            value={chartRiskFreeRate}
                            onChange={(e) => setChartRiskFreeRate(parseFloat(e.target.value) || 0)}
                        />
                        <span>%</span>
                    </div>
                </div>
                <Line data={chartData} options={chartOptions} />
                <div className="chart-range">
                    <div className="input-group">
                        <label>Min</label>
                        <input
                            type="text"
                            value={minPrice}
                            onChange={(e) => setMinPrice(e.target.value)}
                        />
                    </div>
                    <div className="input-group">
                        <label>Max</label>
                        <input
                            type="text"
                            value={maxPrice}
                            onChange={(e) => setMaxPrice(e.target.value)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Demo;
