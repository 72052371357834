import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faVideo, faBook, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import '../styles/pages/Docs.css';

function Docs() {
    const [selectedCard, setSelectedCard] = useState(null);
    const [showExpanded, setShowExpanded] = useState(false);
    const [fade, setFade] = useState('fade-in'); // New state to handle fade classes

    const videoData = {
        "Getting Started": {
            src: "https://www.example.com/video1.mp4",
            title: "Getting Started"
        },
        "Advanced Features": {
            src: "https://www.example.com/video2.mp4",
            title: "Advanced Features"
        },
        "Versions & Updates": {
            src: "https://www.example.com/video3.mp4",
            title: "Versions & Updates"
        }
    };

    const handleCardClick = (card) => {
        setFade('fade-out');
        setTimeout(() => {
            setSelectedCard(card);
            setShowExpanded(true);
            setFade('fade-in');
        }, 500); // Match the duration with the CSS animation timing
    };

    const handleBackClick = () => {
        setFade('fade-out');
        setTimeout(() => {
            setShowExpanded(false);
            setSelectedCard(null);
            setFade('fade-in');
        }, 500); // Match the duration with the CSS animation timing
    };

    const backButton = (
        <button className="back-button" onClick={handleBackClick}>
            <FontAwesomeIcon icon={faArrowLeft} />
        </button>
    );

    return (
        <div className="docs-page">
            {selectedCard && showExpanded ? (
                <div className={`docs-container ${fade}`}>
                    {backButton}
                    <div className="selected-card-content">
                        <h1>{videoData[selectedCard].title} Video</h1>
                        <div className="docs-area-container">
                            <video
                                className="d-block w-100"
                                controls
                                src={videoData[selectedCard].src}
                                alt={`${videoData[selectedCard].title} video`}
                            />
                        </div>
                    </div>
                </div>
            ) : (
                <div className={`primary-docs-container ${fade}`}>
                    <h1>Documentation Videos</h1>
                    <p className="subtitle-docs">Watch our instructional videos to learn how to use our documentation effectively.</p>
                    <div className="docs-cards">
                        <div className="docs-card" onClick={() => handleCardClick('Getting Started')}>
                            <div className="docs-card-icon-wrapper">
                                <FontAwesomeIcon icon={faVideo} className="docs-card-icon" />
                            </div>
                            <h2>Getting Started</h2>
                            <p>Watch our introductory video.</p>
                            <div className="docs-child">
                                <button className="nav-button-docs">Watch Now</button>
                            </div>
                        </div>
                        <div className="docs-card" onClick={() => handleCardClick('Advanced Features')}>
                            <div className="docs-card-icon-wrapper">
                                <FontAwesomeIcon icon={faBook} className="docs-card-icon" />
                            </div>
                            <h2>Advanced Features</h2>
                            <p>Explore advanced features in this video.</p>
                            <div className="docs-child">
                                <button className="nav-button-docs">Watch Now</button>
                            </div>
                        </div>
                        <div className="docs-card" onClick={() => handleCardClick('Versions & Updates')}>
                            <div className="docs-card-icon-wrapper">
                                <FontAwesomeIcon icon={faInfoCircle} className="docs-card-icon" />
                            </div>
                            <h2>Versions & Updates</h2>
                            <p>Keep track of all the latest updates and releases.</p>
                            <div className="docs-child">
                                <button className="nav-button-docs">View Now</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Docs;
