import React, { useEffect, useState } from 'react';
import getStripe from '../lib/getStripe';
import useAuth from '../hooks/useAuth';
import { Card, Button } from 'react-bootstrap';
import '../styles/pages/Purchase.css';

const Purchase = ({ user, loading }) => {
    const { csrfToken, setUser } = useAuth();
    const [localCsrfToken, setLocalCsrfToken] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [billingCycle, setBillingCycle] = useState('yearly');

    const apiUrl = process.env.REACT_APP_DEV_OR_PROD === 'dev' ? process.env.REACT_APP_DEV_FRONT : process.env.REACT_APP_PROD_FRONT;

    useEffect(() => {
        const fetchCsrfToken = async () => {
            try {
                setLocalCsrfToken(csrfToken);
                setIsLoaded(true);
                console.log('CSRF Token fetched:', csrfToken);
            } catch (error) {
                console.error('Error fetching CSRF token:', error);
            }
        };

        fetchCsrfToken();

        console.log("Purchase component mounted");

        return () => {
            console.log("Purchase component unmounted");
        };
    }, []);

    const handleCheckout = async (planType) => {
        console.log('handleCheckout called with:', { loading, user, localCsrfToken });

        if (loading || !user || !localCsrfToken) {
            console.log("Loading, user, or CSRF token not available");
            return;
        }

        const stripe = await getStripe();

        const priceIds = {
            basic: {
                monthly: 'price_1PqbFJAAj0MhVZ7JpGc7E0FS',
                yearly: 'price_1PqbQnAAj0MhVZ7JMAyfhN5d'
            },
            pro: {
                monthly: 'price_1PqbE1AAj0MhVZ7JJWcxqha0',
                yearly: 'price_1PqbXBAAj0MhVZ7JUaJsklZ9'
            },
            enterprise: {
                monthly: 'price_1PqbKeAAj0MhVZ7JofKdrvZ6',
                yearly: 'price_1PqbZwAAj0MhVZ7JiXlM5noq'
            }
        };

        const priceId = priceIds[planType][billingCycle];

        try {
            const response = await fetch(`${apiUrl}/auth/create-checkout-session`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'CSRF-Token': localCsrfToken
                },
                body: JSON.stringify({
                    customerId: user.stripeCustomerId,
                    priceId: priceId,
                }),
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const session = await response.json();

            const result = await stripe.redirectToCheckout({
                sessionId: session.id,
            });

            if (result.error) {
                console.error(result.error.message);
            }
        } catch (error) {
            console.error('Error during checkout:', error);
        }
    };

    const toggleBillingCycle = () => {
        setBillingCycle(billingCycle === 'monthly' ? 'yearly' : 'monthly');
    };

    if (loading || !isLoaded) {
        return <p>Loading...</p>; // Or any loading indicator
    }

    const prices = {
        basic: { original: 69.99, discounted: 55.99 },
        pro: { original: 99.99, discounted: 79.99 },
        enterprise: { original: 79.99, discounted: 71.99 }
    };

    const displayPrices = billingCycle === 'yearly' ? {
        basic: prices.basic,
        pro: prices.pro,
        enterprise: prices.enterprise
    } : prices;

    // Determine the card class based on the billing cycle
    const getCardClass = () => {
        return billingCycle === 'yearly' ? 'purchase-card-yearly' : 'purchase-card-monthly';
    };

    return (
        <div className="purchase-container">
            <div className="billing-toggle">
                <button
                    className={billingCycle === 'monthly' ? 'active' : ''}
                    onClick={toggleBillingCycle}
                >
                    Monthly billing
                </button>
                <button
                    className={billingCycle === 'yearly' ? 'active' : ''}
                    onClick={toggleBillingCycle}
                >
                    Yearly billing
                </button>
            </div>
            <h2 className="purchase-title my-5">Choose Your Plan</h2>
            <div className="card-wrapper">
                <Card className={`purchase-card basic-card ${getCardClass()}`}>
                    <Card.Body>
                        <Card.Title>Basic</Card.Title>
                        <div className='centeriser'>
                            <Card.Subtitle>For beginner users</Card.Subtitle>
                            <h3 className="price-display">
                                {billingCycle === 'yearly' && (
                                    <>
                                        <span className="original-price">${displayPrices.basic.original}</span>
                                        <span className="discounted-price">${displayPrices.basic.discounted}</span>
                                        <span className="price-unit">/mo</span>
                                    </>
                                )}
                                {billingCycle === 'monthly' && (
                                    <>
                                        <span className="discounted-price">${displayPrices.basic.original}</span>
                                        <span className="price-unit">/mo</span>
                                    </>
                                )}
                            </h3>
                            <p className='visibility'>billed {billingCycle}</p>
                            {billingCycle === 'yearly' && (
                                <div className="yearly-discount">
                                    Save 20%! <br />
                                    ${(displayPrices.basic.discounted * 12).toFixed(2)} billed annually
                                    <p></p>
                                </div>
                            )}
                            <Button className="card-button" onClick={() => handleCheckout('basic')}>Purchase Now</Button>
                        </div>
                        <ul className="card-features">
                            <li className="feature-item">Basic price calculation tools</li>
                            <li className="feature-item">1 License Included</li>
                            <li className="feature-item">Email support</li>
                        </ul>
                    </Card.Body>
                </Card>
                <Card className={`purchase-card pro-card ${getCardClass()}`}>
                    <Card.Body>
                        <Card.Title>Pro</Card.Title>
                        <div className='centeriser'>
                            <Card.Subtitle>For advanced users</Card.Subtitle>
                            <h3 className="price-display">
                                {billingCycle === 'yearly' && (
                                    <>
                                        <span className="original-price">${displayPrices.pro.original}</span>
                                        <span className="discounted-price">${displayPrices.pro.discounted}</span>
                                        <span className="price-unit">/mo</span>
                                    </>
                                )}
                                {billingCycle === 'monthly' && (
                                    <>
                                        <span className="discounted-price">${displayPrices.pro.original}</span>
                                        <span className="price-unit">/mo</span>
                                    </>
                                )}
                            </h3>
                            <p className='visibility'>billed {billingCycle}</p>
                            {billingCycle === 'yearly' && (
                                <div className="yearly-discount">
                                    Save 20%! <br />
                                    ${(displayPrices.pro.discounted * 12).toFixed(2)} billed annually
                                    <p></p>
                                </div>
                            )}
                            <Button className="card-button" onClick={() => handleCheckout('pro')}>Purchase Now</Button>
                        </div>
                        <ul className="card-features">
                            <li className="feature-item">Basic price calculation tools</li>
                            <li className="feature-item">Live Exchange Feed</li>
                            <li className="feature-item">1 License Included</li>
                            <li className="feature-item">Priority email support</li>
                            <li className="feature-item">Customizable templates</li>
                        </ul>
                    </Card.Body>
                </Card>
                <Card className={`purchase-card premium-card ${getCardClass()}`}>
                    <Card.Body>
                        <Card.Title>Enterprise</Card.Title>
                        <div className='centeriser'>
                            <Card.Subtitle>For teams of 5 or more</Card.Subtitle>
                            <h3 className="price-display">
                                {billingCycle === 'yearly' && (
                                    <>
                                        <span className="original-price">${displayPrices.enterprise.original}</span>
                                        <span className="discounted-price">${displayPrices.enterprise.discounted}</span>
                                        <span className="price-unit">/pppm</span>
                                    </>
                                )}
                                {billingCycle === 'monthly' && (
                                    <>
                                        <span className="discounted-price">${displayPrices.enterprise.original}</span>
                                        <span className="price-unit">/pppm</span>
                                    </>
                                )}
                            </h3>
                            <p className='visibility'>billed {billingCycle}</p>
                            {billingCycle === 'yearly' && (
                                <div className="yearly-discount">
                                    Save 10%! <br />
                                    ${(displayPrices.enterprise.discounted * 12).toFixed(2)} billed annually
                                    <p></p>
                                </div>
                            )}
                            <Button className="card-button" onClick={() => handleCheckout('enterprise')}>Purchase Now</Button>
                        </div>
                        <ul className="card-features">
                            <li className="feature-item">All Pro features</li>
                            <li className="feature-item">License for multiple users</li>
                            <li className="feature-item">Dedicated account manager</li>
                            <li className="feature-item">24/7 phone support</li>
                            <li className="feature-item">Custom integrations</li>
                        </ul>
                    </Card.Body>
                </Card>
            </div>
        </div>
    );
};

export default Purchase;