import React from "react";
import { Link } from "react-router-dom";
import '../styles/pages/ErrorPage404.css';

function ErrorPage404() {
    return (
        <div className="error-page">
            <div className="error-content">
                <h1 className="error-title">404</h1>
                <h2 className="error-subtitle">Page Not Found</h2>
                <p className="error-message">
                    Oops! The page you are looking for doesn't exist or has been moved.
                </p>
                <Link to="/" className="home-button">
                    Go to Homepage
                </Link>
            </div>
        </div>
    );
}

export default ErrorPage404;
