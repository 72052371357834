import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign, faHeadset, faNewspaper } from '@fortawesome/free-solid-svg-icons';
import '../styles/pages/Contact.css';

function Contact() {
    const [selectedCard, setSelectedCard] = useState(null);
    const navigate = useNavigate();

    const handleCardClick = (card) => {
        setSelectedCard(card);
    };

    const handleBackClick = () => {
        setSelectedCard(null);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        alert('Your message has been sent!');
    };

    const faqData = {
        "Sales": [
            { question: "How do I purchase?", answer: "You can purchase our product by contacting our sales team." },
            { question: "What payment methods are accepted?", answer: "We accept credit cards, PayPal, and wire transfers." },
        ],
        "Help & Support": [
            { question: "How do I reset my password?", answer: "Click on the 'Forgot Password' link on the login page." },
            { question: "How do I contact support?", answer: "You can contact support through the help page or by email." },
        ],
        "Media & Press": [
            { question: "How do I get a press kit?", answer: "You can download our press kit from the Media & Press section." },
            { question: "Who do I contact for interviews?", answer: "Please reach out to our media relations team for interviews." },
        ]
    };

    return (
        <div className="contact-page">
            {selectedCard ? (
                <div className="contact-container">
                    <button onClick={handleBackClick} className="back-button">Back</button>
                    <div className="selected-card-content">
                        <h1>{selectedCard} Inquiry</h1>
                        <div className="contact-area-container">
                            <div className="faq-section">
                                <h2>Frequently Asked Questions</h2>
                                {faqData[selectedCard].map((item, index) => (
                                    <div key={index} className="faq-item">
                                        <div className="faq-question">{item.question}</div>
                                        <div className="faq-answer">{item.answer}</div>
                                    </div>
                                ))}
                            </div>
                            <form onSubmit={handleSubmit} className="contact-form">
                                <div className="form-group">
                                    <label htmlFor="email">Email:</label>
                                    <input type="email" id="email" name="email" required />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="message">Message:</label>
                                    <textarea id="message" name="message" required></textarea>
                                </div>
                                <button type="submit">Send Message</button>
                            </form>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='primary-contact-container'>
                    <h1>Contact us</h1>
                    <p className='subtitle-contact'>Get in touch and let us know how we can help.</p>
                    <div className="contact-cards">
                        <div className="contact-card" onClick={() => handleCardClick('Sales')}>
                            <div className="contact-card-icon-wrapper">
                                <FontAwesomeIcon icon={faDollarSign} className="contact-card-icon" />
                            </div>
                            <h2>Sales</h2>
                            <p>We'd love to talk about how we can work together.</p>
                            <div className='contact-child'>
                                <button className='nav-button-contact'>Contact Sales</button>
                            </div>
                        </div>
                        <div className="contact-card" onClick={() => handleCardClick('Help & Support')}>
                            <div className="contact-card-icon-wrapper">
                                <FontAwesomeIcon icon={faHeadset} className="contact-card-icon" />
                            </div>
                            <h2>Help & Support</h2>
                            <p>We're here to help with any questions or code.</p>
                            <div className='contact-child'>
                                <button className='nav-button-contact'>Get Support</button>
                            </div>
                        </div>
                        <div className="contact-card" onClick={() => handleCardClick('Media & Press')}>
                            <div className="contact-card-icon-wrapper">
                                <FontAwesomeIcon icon={faNewspaper} className="contact-card-icon" />
                            </div>
                            <h2>Media & Press</h2>
                            <p>Get news, company info, and media resources.</p>
                            <div className='contact-child'>
                                <button className='nav-button-contact'>Visit Newsroom</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Contact;
