import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

export const AuthContext = createContext({
    user: null,
    setUser: () => {},
    csrfToken: null,
    setCsrfToken: () => {},
    loading: true,
    setLoading: () => {},
    refreshToken: null,
    setRefreshToken: () => {},
    refreshUserDetails: () => {},
});

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [csrfToken, setCsrfToken] = useState(null);
    const [refreshToken, setRefreshToken] = useState(Cookies.get('refreshToken') || null);
    const [loading, setLoading] = useState(true);

    const apiUrl = process.env.REACT_APP_DEV_OR_PROD === 'dev' ? process.env.REACT_APP_DEV_FRONT : process.env.REACT_APP_PROD_FRONT;

    const refreshUserDetails = async (modoId) => {
        try {
            // Ensure we have a CSRF token before making the request
            if (!csrfToken) {
                await fetchCsrfToken();
            }

            const response = await axios.get(`${apiUrl}/server/refresh-user-details/${modoId}`, {
                headers: {
                    'CSRF-Token': csrfToken
                },
                withCredentials: true
            });

            if (response.data.user) {
                setUser(response.data.user); // Update the user data in context
            }

            return response.data.user;
        } catch (error) {
            console.error('Failed to refresh user details:', error);
            return null;
        }
    };

    const fetchCsrfToken = async () => {
        try {
            const csrfResponse = await axios.get(`${apiUrl}/auth/csrf-token`, { withCredentials: true });
            setCsrfToken(csrfResponse.data.csrfToken);
        } catch (error) {
            console.error('Failed to fetch CSRF token:', error);
        }
    };

    useEffect(() => {
        const initializeAuth = async () => {
            try {
                await fetchCsrfToken(); // Fetch CSRF token once during initialization

                const userResponse = await axios.get(`${apiUrl}/auth/success`, { withCredentials: true });
                setUser(userResponse.data.user);

                // Automatically refresh user details if modoId is present
                if (userResponse.data.user && userResponse.data.user.id) {
                    await refreshUserDetails(userResponse.data.user.id);
                }
            } catch (error) {
                console.log('User not authenticated', error);
            } finally {
                setLoading(false);
            }
        };

        initializeAuth();

        const params = new URLSearchParams(window.location.search);
        const userData = params.get('user');
        if (userData) {
            const user = JSON.parse(decodeURIComponent(userData));
            setUser(user);
            window.history.replaceState({}, document.title, "/dashboard");
            setLoading(false);
        }
    }, []); // Run this effect only once on component mount

    useEffect(() => {
        if (refreshToken) {
            Cookies.set('refreshToken', refreshToken, { secure: true, sameSite: 'Strict' });
        }
    }, [refreshToken]);

    return (
        <AuthContext.Provider value={{
            user, setUser, csrfToken, setCsrfToken,
            loading, setLoading, refreshToken, setRefreshToken,
            refreshUserDetails
        }}>
            {children}
        </AuthContext.Provider>
    );
};

const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error("useAuth must be used within an AuthProvider");
    }
    return context;
};

export default useAuth;
