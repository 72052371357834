import axios from 'axios';
import { useContext, useCallback } from 'react';
import { AuthContext } from '../context/AuthProvider';
import Cookies from 'js-cookie';

const useAuth = () => {
    const { csrfToken, setCsrfToken, setUser, user, loading, setRefreshToken, refreshToken } = useContext(AuthContext);

    const apiUrl = process.env.REACT_APP_DEV_OR_PROD === 'dev' ? process.env.REACT_APP_DEV_FRONT : process.env.REACT_APP_PROD_FRONT;

    const fetchCsrfToken = useCallback(async () => {
        if (!csrfToken) {
            try {
                const response = await axios.get(`${apiUrl}/auth/csrf-token`, { withCredentials: true });
                setCsrfToken(response.data.csrfToken);
            } catch (error) {
                console.error('Failed to fetch CSRF token:', error);
            }
        }
    }, [csrfToken, setCsrfToken]);

    const login = async (username, password) => {
        await fetchCsrfToken();

        console.log('Attempting login with username:', username);

        try {
            const response = await axios.post(`${apiUrl}/auth/login`, { username, password }, {
                headers: {
                    'CSRF-Token': csrfToken
                },
                withCredentials: true
            });

            console.log('Login successful, response:', response.data);
            setUser(response.data.user);
            setRefreshToken(response.data.refreshToken);
            Cookies.set('refreshToken', response.data.refreshToken, { secure: true, sameSite: 'Strict' });
            return response.data;
        } catch (error) {
            console.error('Login failed:', error);
            throw error;
        }
    };

    const refreshAccessToken = async () => {
        await fetchCsrfToken();

        try {
            const response = await axios.post(`${apiUrl}/auth/refresh-token`, {}, {
                headers: {
                    'CSRF-Token': csrfToken
                },
                withCredentials: true
            });

            const { accessToken } = response.data;
            axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
        } catch (error) {
            console.error('Token refresh failed:', error);
        }
    };

    const refreshUserData = async () => {
        try {
            const response = await axios.get(`${apiUrl}/auth/success`, { withCredentials: true });
            setUser(response.data.user);
        } catch (error) {
            console.error('Failed to refresh user data:', error);
        }
    };

    const refreshUserDetailsByModoId = async (modoId) => {
        await fetchCsrfToken();

        try {
            const response = await axios.get(`${apiUrl}/server/refresh-user-details/${modoId}`, {
                headers: {
                    'CSRF-Token': csrfToken // Include the CSRF token in the request headers
                },
                withCredentials: true
            });

            if (response.data.user) {
                setUser(response.data.user); // Update the user data in context
            }
            return response.data;
        } catch (error) {
            console.error('Failed to refresh user details:', error);
            throw error;
        }
    };

    console.log("useAuth hook:", { csrfToken, user, loading, refreshToken });

    return { login, user, loading, setUser, fetchCsrfToken, refreshAccessToken, csrfToken, setCsrfToken, refreshUserData, refreshUserDetailsByModoId };
};

export default useAuth;
