import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Button, Image } from 'react-bootstrap';
import useAuth from '../hooks/useAuth';
import '../styles/components/Navigation.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';

function Navigation() {
    const navigate = useNavigate();
    const { user, setUser } = useAuth();
    const [expanded, setExpanded] = useState(false);

    const handleNavClick = (path) => {
        setExpanded(false);
        navigate(path);
    };

    const handleLogout = async () => {
        try {
            await fetch('/auth/logout', {
                method: 'GET',
                credentials: 'include'
            });
            setUser(null);
            navigate('/login');
        } catch (error) {
            console.error('Logout failed:', error);
        }
    };

    return (
        <Navbar expand="xl" className="bg-body-tertiary main-navbar navbar-dark" expanded={expanded} onToggle={() => setExpanded(!expanded)}>
            <Container>
                <Navbar.Brand className='navbar-brand'>
                    <Image src='/images/ModoLogo.png' className='brand-image d-inline-block align-top' />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="m-auto">
                        <Nav.Link onClick={() => handleNavClick("/home")} className='navbar-item'>Home</Nav.Link>
                        <Nav.Link onClick={() => handleNavClick("/about")} className='navbar-item'>About</Nav.Link>
                        <Nav.Link onClick={() => handleNavClick("/contact")} className='navbar-item'>Contact</Nav.Link>
                        <Nav.Link onClick={() => handleNavClick("/purchase")} className='navbar-item'>Purchase</Nav.Link>
                        <Nav.Link onClick={() => handleNavClick("/docs")} className='navbar-item'>Docs</Nav.Link>
                        <Nav.Link onClick={() => handleNavClick("/demo")} className='navbar-item'>Demo</Nav.Link>
                        {user && (
                            <Nav.Link onClick={() => handleNavClick("/dashboard")} className='navbar-item'>Dashboard</Nav.Link>
                        )}
                    </Nav>
                    <Nav>
                        {user ? (
                            <>
                                <FontAwesomeIcon icon={faBell} className="notification-bell" onClick={() => handleNavClick("/notifications")} />
                                <Button id='sign-out' className='navbar-item' onClick={handleLogout}>Sign Out</Button>
                            </>
                        ) : (
                            <>
                                <Button id='sign-up' className='navbar-item' href='/register'>Sign Up</Button>
                                <Button id='log-in' className='navbar-item' href='/login'>Log In</Button>
                            </>
                        )}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Navigation;
