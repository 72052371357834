import { loadStripe } from '@stripe/stripe-js';

let stripePromise;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe("pk_test_51HJxsfAAj0MhVZ7J5c1KyVKlgv6WfOEJOkrFqMknTl6oReBCbHNhdX6yI6i1i9folQFYLLklH8QdItJcNyjcqiS2002ha19rzq");
  }
  return stripePromise;
};

export default getStripe;