import React from 'react';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import '../styles/pages/About.css';

function About() {
    return (
        <Container className="about-container">
            <h1 className="text-center">About Us</h1>
            <div className="about-content">
                <Row className="custom-row">
                    <Col>
                        <Card className="about-card">
                            <Card.Body>
                                <Card.Title>Welcome</Card.Title>
                                <Card.Text>
                                    Welcome to our company! We are dedicated to providing the best service and
                                    quality products to our customers. Our team of experts is always here to
                                    help you with any questions or concerns.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="equal-height">
                    <Col md={6} className='col-special'>
                        <Card className="about-card special-sizing">
                            <Card.Body>
                                <Card.Title>Our Mission</Card.Title>
                                <Card.Text>
                                    Our mission is to make the world a better place through innovative solutions
                                    and unparalleled customer service. We strive to exceed expectations and set
                                    new standards in our industry.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6} className='col-special'>
                        <Card className="about-card special-sizing">
                            <Card.Body>
                                <Card.Title>Our Values</Card.Title>
                                <Card.Text>
                                    <ul>
                                        <li>Customer Satisfaction</li>
                                        <li>Integrity</li>
                                        <li>Innovation</li>
                                        <li>Teamwork</li>
                                    </ul>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="custom-row">
                    <Col md={12}>
                        <Card className="about-card">
                            <Card.Body>
                                <Card.Title>Our Team</Card.Title>
                                <Card.Text>
                                    Our team is composed of talented professionals from diverse backgrounds.
                                    Together, we work towards a common goal: delivering excellence in all that we do.
                                </Card.Text>
                                <div className="team-profiles-container">
                                    <Row className="team-profiles">
                                        <Col md={4} className="team-member">
                                            <Image src="images/team/Ronnie.jpg" roundedCircle className="team-member-img" />
                                            <h3>Ronnie Chowdhury</h3>
                                            <p>Product Developer</p>
                                        </Col>
                                        <Col md={4} className="team-member">
                                            <Image src="images/team/Muath.jpg" roundedCircle className="team-member-img" />
                                            <h3>Muath Bassam Al-Dossary</h3>
                                            <p>Full Stack Developer</p>
                                        </Col>
                                        <Col md={4} className="team-member">
                                            <Image src="images/team/Salmaan.jpg" roundedCircle className="team-member-img" />
                                            <h3>Salmaan Dawood Yehia</h3>
                                            <p>Full Stack Developer</p>
                                        </Col>
                                    </Row>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="custom-row">
                    <Col md={12}>
                        <Card className="about-card">
                            <Card.Body>
                                <Card.Title>Our History</Card.Title>
                                <Card.Text>
                                    Since our founding in 2024, we have grown from a small startup to a leader in the industry.
                                    Our journey is marked by our commitment to innovation and our unwavering dedication to our customers.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="custom-row">
                    <Col md={12}>
                        <Card className="about-card">
                            <Card.Body>
                                <Card.Title>Contact Us</Card.Title>
                                <Card.Text>
                                    We are here to assist you. Please feel free to reach out to us with any inquiries or feedback.
                                </Card.Text>
                                <ul>
                                    <li>Email: modointerns@gmail.com</li>
                                    <li>Phone: (+44) 7727 145570</li>
                                    <li>Address: 1234 Main St, Anytown, USA</li>
                                </ul>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Container>
    );
}

export default About;
