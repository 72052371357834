// src/components/VideoContainer.js
import React from 'react';
import '../styles/components/VideoContainer.css';

const VideoContainer = ({ videoSrc }) => {
    return (
        <div className="video-container">
            <video autoPlay loop muted playsInline>
                <source src={videoSrc} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </div>
    );
};

export default VideoContainer;
