import { useEffect } from 'react';
import useAuth from './useAuth';

const useRefreshUser = () => {
    const { user, refreshUserDetailsByModoId } = useAuth();

    useEffect(() => {
        if (user && user.id) {
            refreshUserDetailsByModoId(user.id)
                .then((data) => {
                    console.log("User details refreshed:", data);
                })
                .catch((error) => {
                    console.error("Failed to refresh user details:", error);
                });
        }
    }, [user?.id]); // Only trigger when user.id changes
};

export default useRefreshUser;
